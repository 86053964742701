import { Link as RouterLink } from 'react-router-dom';

import {
	Box,
	Heading,
	Container,
	Text,
	Button,
	Stack,
	Link,
	HStack
} from '@chakra-ui/react';
import { FaBrain, FaList } from 'react-icons/fa';



export default function HomeIntro() {

	return (

		<Container maxW={'3xl'}>
			<Stack
				as={Box}
				textAlign={'center'}
				spacing={{ base: 8, md: 14 }}
				py={{ base: 20, md: 36 }}>
				<Heading
					fontWeight={600}
					fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
					lineHeight={'110%'}>
					Alif Central
					<br />

					<Text as={'span'} color={'#92278f'}>
						Desktop, Tablet & Mobile Web App</Text>
				</Heading>
				<Text fontSize={'xl'}>
					FREE & Easy to Use
				</Text>

				<HStack
					direction={'column'}
					spacing={3}
					align={'center'}
					alignSelf={'center'}
					position={'relative'}>

					<Link as={RouterLink} to="/lesson1">
						<Button
							colorScheme="pink"
							leftIcon={<FaBrain />}
							rounded={'full'}
							px={6}
							_hover={{
								bg: '#262261',
							}}
						>Begin
						</Button>
					</Link>

					<Link as={RouterLink} to="/lessons">
						<Button
							leftIcon={<FaList />}
							rounded={'full'}
							px={6}
							colorScheme="purple"
							_hover={{
								bg: '#262261',
							}}
						>Lessons
						</Button>
					</Link>

				</HStack>

			</Stack>

		</Container>

	);
}

