import HomeIntro from './home-intro'
import HomeFeatures from './home-features'


const Home = () => {

	return (
		<>

			<HomeIntro />

			<HomeFeatures />

		</>
	);
}

export default Home;