import { ChakraProvider } from '@chakra-ui/react';
import './App.css'

import AppRoutes from './util/app-routes';

import AppUI from './util/app-ui';


function App() {

	return (

		<ChakraProvider theme={AppUI()} >

			<div className="App">

				<AppRoutes />

			</div>

		</ChakraProvider>

	);
}


export default App;
