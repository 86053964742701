const features =
	[
		{
			id: 1,
			title: 'Content',
			text: 'Based on the Noorani Qaida, the most popular book used to teach people to read the Quran in Arabic'
		},
		{
			id: 2,
			title: 'Audio',
			text: 'Audio Recordings are recited by a qualified reciter & teacher of Quran Recitation with Ijazah!'
		},
		{
			id: 3,
			title: 'Boost',
			text: 'This App is a supplementary learning tool. Students must recite to a qualified teacher to ensure correct pronunciation'
		},
		{
			id: 4,
			title: 'Devices',
			text: 'Access from Desktops, Tablets, Mobile Phones & Televisions and mor'
		},
		{
			id: 5,
			title: 'Platforms',
			text: 'Compatible on most platforms including Android, iOS, Windows, Linux & more. No Ads & completely FREE.'
		},
		{
			id: 6,
			title: 'Install',
			text: 'To install App, simply “Add to Home Screen” via pop-up or via Browser Menu. Yes! That easy, no app store required'
		}

	]

export default features