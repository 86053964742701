import React, { Suspense } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Box, Container } from "@chakra-ui/react";

import Header from '../common/header';
import Home from '../common/home'

const About = React.lazy(() => import('../common/about'));
const Contact = React.lazy(() => import('../common/contact'));
const Page404 = React.lazy(() => import('../common/404'));
const Changelog = React.lazy(() => import('../common/changelog'));

const Lessons = React.lazy(() => import('../components/lessons'));
const Settings = React.lazy(() => import('../components/settings'));
const Lesson = React.lazy(() => import('../components/lesson/lesson'));



// This is the only component that imports the SVG so this removes the size off the main JS chunk
// import Lesson from '../components/lesson/lesson'


const AppRoutes = () => {

	return (

		<BrowserRouter>

			<Header />

			<Suspense fallback={
				<Container maxW='container.sm' mt={10} mb={20} centerContent>
					<Box>Loading...</Box>
				</Container>
			}>

				<Routes>

					<Route path="/" element={<Home />} />
					<Route path="/about" element={<About />} />					
					<Route path="/contact" element={<Contact />} />
					<Route path="/changelog" element={<Changelog />} />

					<Route path="/lessons" element={<Lessons />} />
					<Route path="/settings" element={<Settings />} />

					<Route path="/lesson1" element={<Lesson LessonNumber={1} />} />
					<Route path="/lesson2" element={<Lesson LessonNumber={2} />} />
					<Route path="/lesson3" element={<Lesson LessonNumber={3} />} />
					<Route path="/lesson4" element={<Lesson LessonNumber={4} />} />
					<Route path="/lesson5" element={<Lesson LessonNumber={5} />} />
					<Route path="/lesson6" element={<Lesson LessonNumber={6} />} />
					<Route path="/lesson7" element={<Lesson LessonNumber={7} />} />
					<Route path="/lesson8" element={<Lesson LessonNumber={8} />} />
					<Route path="/lesson9" element={<Lesson LessonNumber={9} />} />
					<Route path="/lesson10" element={<Lesson LessonNumber={10} />} />
					<Route path="/lesson11" element={<Lesson LessonNumber={11} />} />
					<Route path="/lesson12" element={<Lesson LessonNumber={12} />} />
					<Route path="/lesson13" element={<Lesson LessonNumber={13} />} />
					<Route path="/lesson14" element={<Lesson LessonNumber={14} />} />
					<Route path="/lesson15" element={<Lesson LessonNumber={15} />} />
					<Route path="/lesson16" element={<Lesson LessonNumber={16} />} />
					<Route path="/lesson17" element={<Lesson LessonNumber={17} />} />
					<Route path="/lesson18" element={<Lesson LessonNumber={18} />} />

					<Route path="*" element={<Page404 />} />

				</Routes>

			</Suspense>

		</BrowserRouter>

	);
}

export default AppRoutes;